@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;700&display=swap");
@layer base {
  :root {
    --background: 0 0% 100%;
    --background-primary: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --ring: 0 0% 63.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --background-primary: 222.2 84% 4.9%;
    --foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 0 0% 14.9%;
  }
}

@layer base {
  * {
    @apply border-highlight/20;
  }
  body {
    @apply bg-white text-foreground;
  }
}

* {
  font-family: "sofia-pro-soft", system-ui, sans-serif !important;
}

/**
 ** Your own custom utilities
 ** See: https://tailwindcss.com/docs/extracting-components#extracting-component-classes-with-apply
 ** 95%+ of the time, these should not be used; most use cases require a JS component.
 **/

.react-select__value-container div,
.react-select__value-container input {
  --tw-ring-color: transparent !important;
}

.quicksand {
  font-family: "quicksand", system-ui, sans-serif !important;
}

.react-select__control {
  margin-top: 0.25em;
  min-height: 42px !important;
  @apply rounded-md border-slate-200 sm:border-gray-300 !important;
}

#adminDashboard .react-select__control {
  margin-top: 0;
  min-height: 46px !important;
  @apply rounded-md border-slate-200 sm:border-gray-300 !important;
}

.react-select__control--is-focused {
  @apply border border-primary shadow-sm outline-none ring-primary !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.react-select__multi-value {
  background-color: #efefef;
  font-size: 14px !important;
  padding: 2px;
}

.react-select__multi-value__label {
  font-size: 14px !important;
}

.react-select__multi-value__remove {
  cursor: pointer;
}

/* TODO: This is a super hack preventing the blue --is-selected on resource selecet */
.resource-select .react-select__option--is-selected {
  background-color: #ffffff !important;
  color: hsl(0, 0%, 20%) !important;
}

.react-select__option--is-selected {
  background-color: #ffffff !important;
  color: hsl(0, 0%, 20%) !important;
}

.resource-select .react-select__option--is-selected:hover {
  background-color: #deebff !important;
  color: hsl(0, 0%, 20%) !important;
}

.react-select__option--is-selected:hover {
  background-color: #deebff !important;
  color: hsl(0, 0%, 20%) !important;
}

/* End of ugly hack */

.react-select__menu {
  border-radius: 6px !important;
  margin-top: 4px !important;
  background: #ffffff !important;
  box-shadow: none !important;
  z-index: 300 !important;
}

.react-select__menu-portal {
  border-radius: 6px !important;
  margin-top: 4px !important;
  background: #ffffff !important;
  box-shadow: none !important;
  z-index: 300 !important;
}

.react-select__menu-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 6px;
  margin-bottom: 1px !important;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  z-index: 300 !important;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.02), 0 4px 6px -2px
    rgba(0, 0, 0, 0.04);
}

/** React Select Custom Styles **/

form .tailwind__control {
  @apply h-11 border-gray-300 shadow-md;
}

form .tailwind__input input {
  --tw-ring-color: none !important;
}

/**
 **  Write you own custom component styles here
 **  there should  be very, very few of these
 **/

.pricing-header {
  background-image: url("https://res.cloudinary.com/tactic/image/upload/q_auto,f_auto/v1627878402/tactic_new_website/Pricing/bg-pic-1-plain.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.pricing-background {
  background-image: url("https://res.cloudinary.com/tactic/image/upload/q_auto,f_auto/v1627867433/tactic_new_website/Pricing/Untitled_design_95_luqlpw.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.bg-office-layout-image {
  background: url("https://res.cloudinary.com/tactic/image/upload/q_auto,f_auto/v1607117114/office-layout_c0fhuv.png")
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* React Datepicker */

.react-datepicker__month-container {
  float: none !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-wrapper .react-datepicker__input-container > input {
  height: 46px;
  @apply border-slate-200 focus:outline-none focus-visible:ring-primary sm:border-gray-300 !important;
}

.react-datepicker-wrapper .react-datepicker__input-container > input:focus {
  @apply border border-primary !important;
}

.react-datepicker__tab-loop .react-datepicker,
.react-datepicker {
  font-family: sofia-pro-soft, serif !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #f87e3f;
}

.react-datepicker--time-only .react-datepicker__triangle {
  display: none;
}

.react-datepicker--time-only .react-datepicker__header--time--only {
  background-color: #ffffff !important;
  border-bottom: none !important;
}

.react-datepicker--time-only .react-datepicker-time__header {
  text-align: left !important;
  padding-left: 3px !important;
}

.react-datepicker--time-only .react-datepicker__time-box {
  width: 100% !important;
}

.react-datepicker--time-only
  .react-datepicker__time-box
  .react-datepicker__time-list {
  font-size: 14px !important;
  text-align: left !important;
}

/* Mantine Adjustments */
.mantine-Modal-header {
  position: sticky;
  z-index: 10;
}

.mantine-Drawer-header {
  position: sticky;
  z-index: 10;
}

@media screen and (max-width: 600px) {
  .mantine-Modal-root {
    background-color: #ffffff;
  }
}

/* Admin Dashboard Adjustments */
#adminDashboard
  .mantine-Tabs-tab[data-active="true"]
  div.mantine-Tabs-tabLabel {
  color: #233d5d !important;
}

#adminDashboard
  #secondaryTabs
  .mantine-Tabs-tab[data-active="true"]
  div.mantine-Tabs-tabLabel {
  color: #f87e3f !important;
}

#adminDashboard .custom-tactic-select {
  min-height: 46px;
}

#adminDashboard .react-select__placeholder {
  color: black !important;
}

/* TipTap Editor Adjustments */

.mantine-RichTextEditor-content ul {
  list-style: disc;
}

.mantine-RichTextEditor-content ol {
  list-style: decimal;
}

.mantine-RichTextEditor-linkEditorInput {
  min-width: 215px;
}

/**
 * Office Select
 */

.office-select .office-select__control {
  border-radius: 99999px;
  border: 1px solid #e8eef3;
  font-weight: 700;
  padding: 3px 8px;
  color: #223c5b;
}

.office-select__value-container div,
.office-select__value-container input {
  --tw-ring-color: transparent !important;
}

.office-select__placeholder {
  color: #223c5b;
}

.office-select__indicator-separator {
  display: none;
}

.office-select__single-value {
  color: #223c5b;
}

/* This can be avoided by using a third-party library like emotion */
/* More information here: https://reakit.io/docs/tooltip/#animating */
[data-enter] .tooltip-box {
  opacity: 1;
}

.search-input-no-reset[type="search"]::-webkit-search-decoration,
.search-input-no-reset[type="search"]::-webkit-search-cancel-button,
.search-input-no-reset[type="search"]::-webkit-search-results-button,
.search-input-no-reset[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.quote-card-avatar {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transform-origin: top left;
  width: 100px;
  height: 100px;
  border-width: 6px;
  border-radius: 40px;
}

.home-heading-bubble1 {
  position: absolute;
  left: 58%;
  top: 45%;
}
.home-heading-bubble2 {
  position: absolute;
  left: 25%;
  top: 20%;
}

/* Mantine Customizations */
.mantine-RichTextEditor-content {
  min-height: 150px;
}

.mantine-tactic-display .mantine-RichTextEditor-content {
  min-height: 0;
}

.mantine-Select-input {
  border: 1px solid #7b899c;
  border-radius: 1.75rem;
}

/* Reverses text when an element has been vertically flipped
 * by 3D animation so that it's still readable.
*/
.mirror-text {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}

.nvst-TextFieldChrome {
  position: absolute;
  inset: 0;
  z-index: 0;
  pointer-events: none;
}

:where(input:focus) + .nvst-form-control-wrapper-outline,
:where(input:focus-visible) + .nvst-form-control-wrapper-outline {
  visibility: visible !important;
}
